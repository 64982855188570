import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'

const ContactPage = ({
  data: {
    site
  }
}) => {
  const { t } = useTranslation()
  const tt = (key) => t(`contact.${key}`)
  return (
    <>
      <Helmet>
        <title>{tt('contact')} — {site.siteMetadata.title}</title>
        <meta name='description' content={'Contact page of ' + site.siteMetadata.description} />
      </Helmet>
      <div className='two-grids -contact'>
        <div className='post-thumbnail' style={{ backgroundImage: `url('/assets/alexander-andrews-HgUDpaGPTEA-unsplash.jpg')`, marginBottom: 0 }}>
          <h1 className='post-title'>{tt('get-in-touch')}</h1>
          <p>Let me help you kick start your next project &rarr;</p>
        </div>
        <div>
          <form className='form-container' action='https://sendmail.w3layouts.com/SubmitContactForm' method='post'>
            <div>
              <label htmlFor='w3lName'>Name</label>
              <input type='text' name='w3lName' id='w3lName' />
            </div>
            <div>
              <label htmlFor='w3lSender'>Email</label>
              <input type='email' name='w3lSender' id='w3lSender' />
            </div>
            <div>
              <label htmlFor='w3lSubject'>Subject</label>
              <input type='text' name='w3lSubject' id='w3lSubject' />
            </div>
            <div>
              <label htmlFor='w3lMessage'>Message</label>
              <textarea name='w3lMessage' id='w3lMessage' />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <input type='submit' className='button -primary' style={{ marginRight: 0 }} />
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
export default ContactPage
export const pageQuery = graphql`
  query ContactPageQuery{
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
